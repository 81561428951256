import axios from 'axios';
import qs from 'qs';
import { Base64 } from 'js-base64';

const apiKey = process.env.REACT_APP_DODDLE_API_KEY || '';
const apiSecret = process.env.REACT_APP_DODDLE_API_SECRET || '';
const urlBase = process.env.REACT_APP_LEGACY_DODDLE_API_URL || '';

const getToken = () => {
  const body = qs.stringify({
    grant_type: 'client_credentials', //eslint-disable-line
    scope: 'ratings:write items_collections-website',
  });

  return axios.post(`${urlBase}/v1/oauth/token`, body, {
    headers: {
      Authorization: `Basic ${Base64.encode(`${apiKey}:${apiSecret}`)}`,
    },
  });
};

export const sendRating = async (body: any) => {
  const tokens = await getToken();

  return axios.post(`${urlBase}/v1/ratings/`, body, {
    headers: {
      Authorization: `Bearer ${tokens.data.access_token}`,
    },
  });
};
