import React, { useEffect } from 'react';
import {
  Page,
  Header,
  Main,
  YamatoLogo,
  PrimaryText,
  StarsContainer,
  Star,
  Divider,
  ContentDiv,
  PaddingDiv,
} from 'components/styled';
import { starActive, starDisabled, yamatoImage } from 'assets';
import { sendRating } from 'api';
import qs from 'qs';

function App() {
  const { interaction_id, service_id, rate } = qs.parse(
    window.location.search,
    { ignoreQueryPrefix: true }
  );

  useEffect(() => {
    window.history.pushState('', '', '/');

    if (interaction_id && service_id && rate) {
      sendRating({
        interactionType: 'YAMATO_COLLECTION',
        interactionId: interaction_id,
        serviceType: 'STORE',
        serviceId: service_id,
        ratingType: 'FIVE_STARS',
        ratingValue: rate,
      });
    }
  }, [interaction_id, service_id, rate]);

  const stars = [...Array(5)].map((_, index) => (
    <Star
      src={Number(rate!) >= index + 1 ? starActive : starDisabled}
      key={index}
      alt="star-image"
    />
  ));

  return (
    <Page>
      <Header>
        <PaddingDiv/>
        <YamatoLogo src={yamatoImage} alt="Yamato Logo" />
      </Header>
      <Divider/>
      <Main>
        <ContentDiv>
          <PrimaryText>
            評価いただき、ありがとうございます
          </PrimaryText>
          <StarsContainer>{stars}</StarsContainer>
        </ContentDiv>
      </Main>
    </Page>
  );
}

export default App;
