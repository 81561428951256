import styled from 'styled-components';

export const Page = styled.div`
  background: rgb(250,250,250);  
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

export const Header = styled.header`
  display: flex;
  padding: 30px;
`;
export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
`;

export const Divider = styled.div`
  border-bottom: 2px solid rgb(239, 239, 239);
  width: 100%;
`;

export const ContentDiv = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 10px 15px rgba(0,0,0,0.3);
  flex: 0 1 auto;
  flex-basis: 45%;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
  min-height: 350px;
`;

export const PaddingDiv = styled.div`
  flex-basis: 10%;
`

export const YamatoLogo = styled.img`
  background-size: cover;
  background-repeat: no-repeat;
  height: 100px;
  width: 135px;
`;

export const PrimaryText = styled.h1`
  align-items: center;  
  display: flex;
  color: rgb(41, 41, 41);
  flex: 0 1 auto;
  flex-basis: 40%;
  font-size: 40px;
  font-weight: bold;
  height: 100%;
  text-align: center;
  margin: 25px 20px 5px 20px;
  max-height: 50%;
  text-transform: uppercase;
`;

export const StarsContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-basis: 40%;
  justify-content: center;
  margin: 20px;
  max-height: 50%;
`;

export const Star = styled.img`
  margin-left: 6px;
  height: 70px;
  width: 70px;
`;
